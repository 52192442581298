<template>
  <div class="pick-team-topbar pl-2 pr-2">
    <div class="pick-team-topbar__lock-group ml-3">
      <template v-if="isLockAvailable">
        <v-icon small style="width: 20px" color="#DADADA"
          >lock_outlined
        </v-icon>
        <WeveToggle
          label="Lock Game"
          class="pick-team-topbar__switch"
          v-model="locked"
          color="#8680ff"
        />
      </template>
    </div>

    <div class="pick-team-topbar__select-group">
      <SettingsSelector
        style="width: 65px"
        default-title="Go"
        default-icon="directions_run"
        selected-prop="name"
        v-model="goOption"
        :items="goOptions"
      />

      <RtbControlButton sm color="success" @click="pushToGame">
        {{ enterGameText }}
      </RtbControlButton>
    </div>

    <div class="pick-team-topbar__music-group">
      <GameMusicPlayer />
      <AudioDrawerToggle />
    </div>

    <v-dialog
      v-if="isGameSettingsVisible"
      :value="isGameSettingsVisible"
      persistent
      no-click-animation
      width="1024px"
    >
      <game-settings :game="gameToUpdate" @close="closeGameSettings" />
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters, mapActions } from "vuex"
import GameMusicPlayer from "./GameMusicPlayer"
import { WeveToggle } from "@weve/ui"
import { GoItem } from "./toolbar-options"
import SettingsSelector from "@/components/SettingsSelector"
import AudioDrawerToggle from "@/components/drawers/audio/AudioDrawerToggle"
import { RtbControlButton } from "@/components/ui"

import Page from "@/router/Page"

export default Vue.extend({
  name: "PickTeamsTopBar",
  data: () => ({
    goOption: null,
    isGameSettingsVisible: false
  }),
  components: {
    RtbControlButton,
    GameSettings: () => import("@/components/Game/GameSettings.vue"),
    WeveToggle,
    GameMusicPlayer,
    AudioDrawerToggle,
    SettingsSelector
  },
  watch: {
    goOption(value) {
      this.goOption = null
      if (value === GoItem.RETURN_GAME || value === GoItem.START_GAME)
        return this.pushToGame()

      if (value === GoItem.EDIT_ACTIVITIES_CT)
        return window.open(
          `/content/room/${this.game.contentToolID}/`,
          "_blank"
        )

      if (value === GoItem.EDIT_ACTIVITIES)
        return this.$router.push(`/game/${this.urlID}/settings`)

      if (value === GoItem.EDIT_GAME_SETTINGS) return this.pushToGameSettings()

      if (value === GoItem.EDIT_EVENT_SETTINGS)
        return this.$router.push({
          name: Page.SESSION_SETTINGS,
          params: { id: this.clientID }
        })
    }
  },
  computed: {
    ...mapGetters(["urlID", "actualGameID", "game", "orgID", "settingsBar"]),
    ...mapGetters("auth", ["isHost", "isModerator", "client", "clientID"]),
    isLockAvailable() {
      return !this.client?.tournament
    },
    locked: {
      set(value) {
        this.updateGameAny({
          theKey: this.game?.id,
          locked: value
        })
      },
      get() {
        return Boolean(this.game?.locked)
      }
    },
    goOptions() {
      const options = []

      if (this.game.started) {
        options.push({
          name: GoItem.RETURN_GAME,
          icon: "keyboard_return",
          iconColor: "#00E699"
        })
      } else {
        options.push({
          name: GoItem.START_GAME,
          svgIcon: "play",
          iconColor: "#00E699"
        })
      }

      if (!this.isModerator) {
        if (this.game?.contentToolID && !this.game.templateID)
          options.push({
            name: GoItem.EDIT_ACTIVITIES_CT,
            svgIcon: "games"
          })

        options.push({
          name: GoItem.EDIT_ACTIVITIES,
          svgIcon: "games"
        })

        options.push({
          name: GoItem.EDIT_GAME_SETTINGS,
          svgIcon: "settings-applications"
        })
      }

      if (!this.client?.tournament) {
        options.push({
          name: GoItem.EDIT_EVENT_SETTINGS,
          svgIcon: "settings-applications"
        })
      }

      return options
    },
    enterGameText() {
      if (this.game?.started) {
        return GoItem.RETURN_GAME
      } else {
        return GoItem.START_GAME
      }
    }
  },
  methods: {
    ...mapActions("Games", ["updateGameAny"]),
    pushToGameSettings() {
      // TODO replace to call edit settings window
      this.gameToUpdate = { ...this.game }
      this.gameToUpdate.theKey = this.gameID
      this.isGameSettingsVisible = true
    },
    closeGameSettings() {
      this.gameToUpdate = null
      this.isGameSettingsVisible = false
    },
    async pushToGame() {
      try {
        // why?
        await this.$store.dispatch("fetchMissions", this.actualGameID)
      } catch (e) {
        console.error(e)
      }
      await this.$navigator.navigateTo(`/game/${this.urlID}`)
    }
  }
})
</script>

<style lang="scss">
.pick-team-topbar {
  background: #292932;
  display: flex;
  align-items: center;
  height: 46px;
  justify-content: space-between;

  &__lock-group,
  &__select-group,
  &__music-group {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &__select-group {
    justify-content: space-around;
  }

  &__music-group {
    justify-content: flex-end;
  }

  &__switch {
    .v-input--switch__track {
      border-radius: 20px;
      height: 20px;
      left: 2px;
      opacity: 0.6;
      position: absolute;
      right: 2px;
      top: calc(50% - 10px);
      width: 40px;
      background: #798294;
    }
  }

  &__switch.v-input--switch.v-input--is-dirty
    .v-input--selection-controls__ripple,
  &__switch.v-input--switch.v-input--is-dirty .v-input--switch__thumb {
    transform: translate(22px, 0) !important;
  }
}
</style>
