var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pick-team-topbar pl-2 pr-2" },
    [
      _c(
        "div",
        { staticClass: "pick-team-topbar__lock-group ml-3" },
        [
          _vm.isLockAvailable
            ? [
                _c(
                  "v-icon",
                  {
                    staticStyle: { width: "20px" },
                    attrs: { small: "", color: "#DADADA" },
                  },
                  [_vm._v("lock_outlined ")]
                ),
                _c("WeveToggle", {
                  staticClass: "pick-team-topbar__switch",
                  attrs: { label: "Lock Game", color: "#8680ff" },
                  model: {
                    value: _vm.locked,
                    callback: function ($$v) {
                      _vm.locked = $$v
                    },
                    expression: "locked",
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "pick-team-topbar__select-group" },
        [
          _c("SettingsSelector", {
            staticStyle: { width: "65px" },
            attrs: {
              "default-title": "Go",
              "default-icon": "directions_run",
              "selected-prop": "name",
              items: _vm.goOptions,
            },
            model: {
              value: _vm.goOption,
              callback: function ($$v) {
                _vm.goOption = $$v
              },
              expression: "goOption",
            },
          }),
          _c(
            "RtbControlButton",
            {
              attrs: { sm: "", color: "success" },
              on: { click: _vm.pushToGame },
            },
            [_vm._v(" " + _vm._s(_vm.enterGameText) + " ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pick-team-topbar__music-group" },
        [_c("GameMusicPlayer"), _c("AudioDrawerToggle")],
        1
      ),
      _vm.isGameSettingsVisible
        ? _c(
            "v-dialog",
            {
              attrs: {
                value: _vm.isGameSettingsVisible,
                persistent: "",
                "no-click-animation": "",
                width: "1024px",
              },
            },
            [
              _c("game-settings", {
                attrs: { game: _vm.gameToUpdate },
                on: { close: _vm.closeGameSettings },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }